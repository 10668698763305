<!-- 培训班级类别列表新增 -->
<template>
    <div class="AddList">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">培训班级类别列表新增</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="编码">
                <el-input v-model="form.codeNum" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="培训科目" prop="subject">
                <!-- <span @click="subject" slot="label" style="color: #26716C;font-weight: bold;cursor:pointer;">
                    培训科目
                </span> -->
                <!-- <el-input v-model="form.subject" placeholder="请选择培训科目" disabled></el-input> -->
                <el-select v-model="form.subject"  placeholder="请选择培训科目" :clearable="true">
                    <el-option v-for="item in form.subjectOpt" :key="item.id" :label="item.course_name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="主责老师" >
                <!-- <span @click="principal" slot="label" style="color: #26716C;font-weight: bold;cursor:pointer;">
                    主责老师
                </span>
                <el-input v-model="form.principal" placeholder="请选择主责老师" disabled></el-input> -->
                <el-select v-model="form.principal" filterable placeholder="请输入并选择主责老师" :clearable="true">
                    <el-option v-for="item in form.principalOpt" :key="item.id" :label="item.fullname" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="授课次数" >
                <el-input v-model="form.frequency" placeholder="请输入授课次数" onkeyup="value=value.replace(/[^\d]/g,'')">
                </el-input>
            </el-form-item>
            <el-form-item label="培训班名称" prop="className">
                <el-input v-model="form.className" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.conditionChose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="启用日期">
                <el-date-picker v-model="form.enableDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择启用日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="停用日期">
                <el-date-picker v-model="form.stopDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择停用日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="每班人数">
                <el-input v-model="form.peopleNum" placeholder="请输入每班人数" onkeyup="value=value.replace(/[^\d]/g,'')">
                </el-input>
            </el-form-item>
            <el-form-item label="学杂费标准(元/人)">
                <el-input v-model="form.outlay" placeholder="请输入学杂费标准(元/人)" onkeyup="value=value.replace(/[^\d]/g,'')">
                </el-input>
            </el-form-item>
            <el-form-item label="其中学费(元/人)">
                <el-input v-model="form.tuition" placeholder="请输入其中学费(元/人)" onkeyup="value=value.replace(/[^\d]/g,'')">
                </el-input>
            </el-form-item>
            <el-form-item label="杂费(元/人)">
                <el-input v-model="form.incidental" placeholder="请输入杂费(元/人)" 
                onkeyup="value=value.replace(/[^\d]/g,'')">
                </el-input>
            </el-form-item>
            <el-form-item label="说明">
                <el-input type="textarea" class="textArea-width" v-model="form.explain" 
                placeholder="请输入"  rows="4"></el-input>
            </el-form-item>
        </el-form>

        <!-- 培训科目弹窗 -->
        <el-dialog title="学科" :visible.sync="dialog">
            <el-form :model="ruleform" class="ruleform">
                <el-form-item label="">
                    <el-input v-model="ruleform.search" placeholder="请输入关键字"></el-input>
                </el-form-item>
                <div class="" style="margin-left: 25px;margin-top: 5px;">
                    <el-button size="small" style="background: #18BC9C;color: #FFFFFF;">
                        查询
                    </el-button>
                    <el-button size="small" style="background: #FAFAFA;color: #666666;">
                        重置
                    </el-button>
                </div>
            </el-form>
            <!-- 树形菜单 -->
            <el-tree :data="data" node-key="id" ref="tree" highlight-current :props="defaultProps">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span @click="add(node)" @dblclick="voluation(node)">{{node.label}}</span>
                </span>
            </el-tree>
        </el-dialog>

        <!-- 主责老师弹窗 -->
        <el-dialog title="人力资源" :visible.sync="Visible" class="dig">
            <el-form :model="ruleform" class="ruleform">
                <el-form-item label="">
                    <el-input v-model="ruleform.search" placeholder="请输入关键字"></el-input>
                </el-form-item>
                <div class="" style="margin-left: 25px;margin-top: 5px;">
                    <el-button size="small" style="background: #18BC9C;color: #FFFFFF;">
                        查询
                    </el-button>
                    <el-button size="small" style="background: #FAFAFA;color: #666666;">
                        重置
                    </el-button>
                </div>
            </el-form>
            <!-- 列表 表格 -->
            <div class="" style="height: 480px; display: flex;flex-direction: column;">
                <el-table :data="listData" border stripe style="width:96%;flex: 1;" height="480"
                    @row-click="openDetails">
                    <!-- 复选按钮 -->
                    <el-table-column type="selection" align="center" width="50">
                    </el-table-column>
                    <el-table-column prop="num" label="序号" align="center" width="80">
                    </el-table-column>
                    <el-table-column prop="" label="员工工号" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="" label="人员姓名" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="" label="英文姓名" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="" label="职务" align="center" width="100">
                    </el-table-column>
                    <el-table-column prop="" label="所在部门" align="center" width="100">
                    </el-table-column>
                    <el-table-column prop="" label="联系电话" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="" label="移动电话" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="" label="联系邮件" align="center" width="150">
                    </el-table-column>
                </el-table>
                <!--分页 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="10" background
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>

        </el-dialog>

        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    codeNum: "", //编码
                    subject: "", //培训科目
                    subjectOpt:[],//科目数据
                    principal: "", //主责老师
                    principalOpt: [], //主责老师选项
                    frequency: "", //授课次数
                    className: "", //培训班名称
                    condition: "1", //状态
                    conditionChose: [{
                            value: '1',
                            label: '准备'
                        },
                        {
                            value: '2',
                            label: '可用'
                        },
                        {
                            value: '3',
                            label: '停用'
                        }
                    ], //状态选项
                    enableDate: "", //启用日期
                    stopDate: "", //停用日期
                    peopleNum: "", //每班人数
                    outlay: "", //学杂费标准
                    tuition: "", //其中学费
                    incidental: "", //杂费
                    explain: "" //说明
                },
                rules: {
                    subject: [{
                        required: true,
                        message: '培训科目不能为空',
                        trigger: 'blur'
                    }, ],
                    principal: [{
                        required: true,
                        message: '主责老师不能为空',
                        trigger: 'blur'
                    }, ],
                    frequency: [{
                        required: true,
                        message: '授课次数不能为空',
                        trigger: 'blur'
                    }, ],
                    className: [{
                        required: true,
                        message: '培训班名称不能为空',
                        trigger: 'blur'
                    }, ],
                },
                ruleform: {
                    search: "" //搜索
                },
                dialog: false,
                data: [{
                    id: 1,
                    label: '学科数据',
                    children: [{
                        id: 2,
                        label: '考级类别',
                        children: [{
                            id: 4,
                            label: '三级 1-1-1',
                            children: [{
                                id: 7,
                                label: '四级 1-1-1-1'
                            }]
                        }, {
                            id: 5,
                            label: '三级 1-1-2',
                            children: [{
                                id: 8,
                                label: '四级 1-1-2-1'
                            }]
                        }]
                    }, {
                        id: 3,
                        label: '教学科目',
                        children: [{
                            id: 6,
                            label: '三级 1-2-1',
                            children: [{
                                id: 9,
                                label: '四级 1-2-1-1'
                            }]
                        }]
                    }]
                }],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                // 主责老师弹窗
                Visible: false,
                listData: [{
                    num: '1',
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市-普陀区-金沙江'
                }],
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
                bool:false
            }
        },
        created() {
            // 培训科目数据
            this.$request({
                url: '/api/course/list',
                method: "POST",
                data: {
                    page: 1,
                    limit: 100
                }
            }).then(res => {
                console.log(res, "培训科目")
                this.form.subjectOpt = res.data.list
            })
            
            // 主责老师数据
            this.$request({
                url: '/api/humanresources/list',
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log(res, "主责老师")
                this.form.principalOpt = res.data.list
            })
        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        if(this.bool){
                            return
                        }
                        this.bool=true
                        let form = this.form
                        this.$request({
                            url: '/api/classcategory/add',
                            method: "POST",
                            data: {
                                number:form.codeNum,//编码
                                course_id:form.subject,//培训科目
                                resources_id:form.principal,//主责老师
                                lecture_number:form.frequency,//授课次数
                                category_name:form.className,//培训班名称
                                category_status:form.condition,//状态
                                start_time:form.enableDate,//启用日期
                                end_time:form.stopDate,//停用日期
                                category_size:form.peopleNum,//每班人数
                                tuition_incidentals:form.outlay,//学杂费
                                tuition:form.tuition,//学费
                                incidentals:form.incidental,//杂费
                                remark:form.explain//说明
                            }
                        }).then(res => {
                            if(res.code==1){
                                this.$message({
                                  message: '新增列表成功',
                                  type: 'success'
                                })
                                setTimeout(() => {
                                	this.goBack()	
                                }, 1500)
                            }else{
                                this.$message({
                                  message:res.msg ,
                                  type: 'error'
                                })
                                setTimeout(() => {
                                	this.goBack()	
                                }, 1500)
                            }
                        })
                        
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                // this.$router.push({
                //     path: "classCategory"
                // })
                this.$router.go(-1)
                this.$refs[form].resetFields();
            },
            principal() { //主责老师弹窗
                this.Visible = true
            },
            subject() { //培训科目弹窗
                this.dialog = true
            },
            add(node) { //输出节点
                // console.log(node)
            },
            voluation(e) { //双击赋值
                if (JSON.stringify(e.childNodes) === '[]') {
                    console.log('最后一层', e);
                    this.form.subject = e.data.label
                    this.dialog = false
                }
            },
            openDetails(row) { //表格点击事件
                console.log(row)
                // this.form.principal=
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
        }
    }
</script>

<style scoped="scoped">
    .AddList {
        width: 100%;
        height: 100%;
    }

    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }

    ::v-deep .el-form-item__label {
        color: #000000;
    }

    #form-1 .el-input {
        width: 300px;
        height: 37px !important;
    }

    .el-select {
        width: 300px;
    }

    ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
    }

    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }

    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }

    /* 培训科目弹窗 */
    .ruleform ::v-deep .el-input__inner {
        width: 173px;
        height: 33px;
        margin-left: 20px;
    }

    ::v-deep .el-tree-node__content {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        margin-left: 10px;
        margin-top: 5px;
    }

    ::v-deep .el-tree-node__children .el-tree-node__content {
        color: #333;
    }

    ::v-deep .el-dialog__body {
        /* padding: 5px; */
        margin-bottom: 30px;
    }

    .el-tree {
        height: 600px !important;
    }

    /* 主责老师弹窗 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0px;
        height: 35px;
    }

    ::v-deep .dig .el-dialog {
        width: 900px !important;
        height: 600px !important;
        display: flex !important;
        flex-direction: column !important;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
